import { IncomeFrequencyTypes, useTenantSettings } from '@nestoca/multi-tenant';

import { useI18n } from 'providers/i18n/use-i18n';

type IncomeFrequency = typeof IncomeFrequencyTypes;

type IncomeFrequencyValue = IncomeFrequency[keyof IncomeFrequency];

type IncomeFrequencyMap = Record<
    IncomeFrequencyValue,
    {
        id: IncomeFrequencyValue;
        labelKey: string;
    }
>;

export const INCOME_FREQUENCY_MAP: IncomeFrequencyMap = {
    [IncomeFrequencyTypes.ANNUALLY]: {
        id: IncomeFrequencyTypes.ANNUALLY,
        labelKey: 'frequency.yr',
    },
    [IncomeFrequencyTypes.SEMIANNUALLY]: {
        id: IncomeFrequencyTypes.SEMIANNUALLY,
        labelKey: 'frequency.sa',
    },
    [IncomeFrequencyTypes.QUARTERLY]: {
        id: IncomeFrequencyTypes.QUARTERLY,
        labelKey: 'frequency.quarterly',
    },
    [IncomeFrequencyTypes.MONTHLY]: {
        id: IncomeFrequencyTypes.MONTHLY,
        labelKey: 'frequency.mo',
    },
    [IncomeFrequencyTypes.SEMIMONTHLY]: {
        id: IncomeFrequencyTypes.SEMIMONTHLY,
        labelKey: 'frequency.sm',
    },
    [IncomeFrequencyTypes.BIWEEKLY]: {
        id: IncomeFrequencyTypes.BIWEEKLY,
        labelKey: 'frequency.bw',
    },
    [IncomeFrequencyTypes.WEEKLY]: {
        id: IncomeFrequencyTypes.WEEKLY,
        labelKey: 'frequency.wkly',
    },
};

export const useIncomeFrequencyOptions = () => {
    const { i18n } = useI18n();

    const {
        typesOverride: {
            settings: { incomeFrequencyTypes },
        },
    } = useTenantSettings();

    return incomeFrequencyTypes
        .filter((typeId) => !!INCOME_FREQUENCY_MAP[typeId])
        .map((typeId) => ({
            value: typeId,
            label: i18n._(INCOME_FREQUENCY_MAP[typeId].labelKey),
        }));
};
