import React from 'react';

import { useTenantSetting } from '@nestoca/multi-tenant';
import { Flex, Grid, Typography } from '@nestoca/ui';
import cn from 'classnames';

import { EditableCell } from 'components/editable';
import {
    TENURE,
    EMPLOYMENT_TYPE,
    OCCUPATIONS_LABELS,
    BORROWER_OCCUPATION,
} from 'constants/appConstants';
import { useIncomeOptions } from 'constants/icnome';
import { useI18n } from 'providers/i18n/use-i18n';
import { EmploymentIncomeType, IncomeEmployment } from 'types/applicant';
import { compareForId } from 'utils/validations/comparators';

import styles from './section.module.scss';

type Props = {
    isCreating: boolean;
    isEditing: boolean;
    incomeTypeWatch: EmploymentIncomeType;
    employment: IncomeEmployment & { totalIncome?: number };
};

export const EmploymentDetails = ({
    isCreating,
    isEditing,
    incomeTypeWatch,
    employment,
}: Props) => {
    const { i18n } = useI18n();

    const {
        settings: { enableIncompleteEmploymentData },
    } = useTenantSetting('applicantSettings');

    const {
        employmentType,
        hasGuaranteedHours,
        incomeType,
        isCurrent,
        tenure,
    } = employment;

    const showGuaranteedHours = ['HOURLY', 'HOURLY_COMMISSIONS'];
    const incomeOptions = useIncomeOptions();

    const tenureOptions =
        incomeTypeWatch === 'ON_CONTRACT'
            ? TENURE(i18n).filter((option) => option.value !== 'ON_CONTRACT')
            : TENURE(i18n);

    return (
        <Flex
            className={cn(styles.section, {
                [styles['section--modal']]: isCreating,
            })}
            direction="column"
            gap={4}
        >
            <Typography weight={7} textColor="var(--color-black)">
                {i18n._('employmentDetails')}
            </Typography>
            <Grid
                className={cn(styles.grid, {
                    [styles['grid--auto-fit']]: isCreating,
                })}
                gap={4}
            >
                {!isCreating && (
                    <>
                        <EditableCell
                            required
                            isEditing={isEditing}
                            name="incomeType"
                            label="Income Type"
                            fieldType="readonly"
                            options={incomeOptions}
                            value={incomeType}
                            isFieldInvalidCompareFn={compareForId(employment)}
                        />
                        <EditableCell
                            required
                            isEditing={isEditing}
                            name="occupation"
                            label="occupation"
                            fieldType="select"
                            value={
                                OCCUPATIONS_LABELS(i18n)[employment.occupation]
                            }
                            options={BORROWER_OCCUPATION(i18n)}
                            isFieldInvalidCompareFn={compareForId(employment)}
                        />
                    </>
                )}
                <EditableCell
                    required
                    isEditing={isEditing}
                    name="isCurrent"
                    label="currentEmployer"
                    fieldType="boolean"
                    value={isCurrent}
                    isFieldInvalidCompareFn={compareForId(employment)}
                />
                {showGuaranteedHours.includes(incomeTypeWatch) && (
                    <EditableCell
                        required
                        isEditing={isEditing}
                        name="hasGuaranteedHours"
                        label="guaranteedHours"
                        fieldType="boolean"
                        value={hasGuaranteedHours}
                        isFieldInvalidCompareFn={compareForId(employment)}
                    />
                )}
                <EditableCell
                    required={!enableIncompleteEmploymentData}
                    isEditing={isEditing}
                    name="tenure"
                    label="tenure"
                    fieldType="select"
                    options={tenureOptions}
                    value={tenure}
                    isFieldInvalidCompareFn={compareForId(employment)}
                />
                <EditableCell
                    required
                    isEditing={isEditing}
                    name="employmentType"
                    label="employmentType"
                    fieldType="select"
                    options={EMPLOYMENT_TYPE(i18n)}
                    value={employmentType}
                    isFieldInvalidCompareFn={compareForId(employment)}
                />
            </Grid>
        </Flex>
    );
};
