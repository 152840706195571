import { useRecoilValue } from 'recoil';

import { EditableCell } from 'components/editable';
import { getApplicantsSelectOptions } from 'store/applications';

type ApplicantSelectorProps = {
    applicationId: number;
    isEditing: boolean;
    applicantId?: number;
};

const ApplicantSelector = ({
    applicationId,
    isEditing,
    applicantId,
}: ApplicantSelectorProps) => {
    const applicantOptions = useRecoilValue(
        getApplicantsSelectOptions(applicationId)
    );

    return (
        <EditableCell
            isEditing={isEditing}
            fieldType="select"
            name="applicantId"
            label="applicant"
            options={applicantOptions}
            value={applicantId}
        />
    );
};

export default ApplicantSelector;
