import { minMoneyOf, minMoneyRequiredOf, numberRequired } from './number';
import { stringRequired } from './string';
import yup from './yup-extended';

import type { I18n } from '@lingui/core';

export const getLiabilityPartialSchema = (i18n: I18n) =>
    yup.object().shape({
        coOwnerApplicantIds: yup.array().of(yup.number()).nullable(),
        included: yup.boolean().emptyAsNull().nullable(),
        type: yup.string().nullable(),
        highCredit: minMoneyOf(i18n).emptyAsNull(),
        balance: minMoneyOf(i18n).emptyAsNull(),
        currency: yup.string().nullable(),
        creditType: stringRequired(i18n).nullable(),
        description: yup.string().nullable(),
        payment: yup.object().shape({
            amount: minMoneyRequiredOf(i18n).emptyAsNull(),
            frequency: stringRequired(i18n).nullable().emptyAsNull(),
        }),
        paymentMultiplier: yup.string().nullable(),
        payoff: yup.string().nullable(),
    });

// Validation to create a liability
export const getCreateLiabilityPartialSchema = (i18n: I18n) =>
    yup
        .object()
        .shape({
            applicantId: numberRequired(i18n).nullable(),
        })
        .concat(getLiabilityPartialSchema(i18n));
