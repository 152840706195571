import { I18n } from '@lingui/core';
import * as yup from 'yup';

/**
 * This function is used to get the select schema
 * @param i18n I18n instance
 * @returns The select schema
 */
export const select = () =>
    yup
        .object()
        .shape({
            label: yup.string(),
            value: yup.string(),
        })
        .nullable();

/**
 * This function is used to get the required select schema
 * @param i18n I18n instance
 * @returns The required select schema
 */
export const selectRequired = (i18n: I18n) =>
    select().required(i18n._('error.fieldRequired'));

/**
 * This function is used to get the multi select schema
 * @param i18n I18n instance
 * @returns The multi select schema
 */
export const multiSelect = () => yup.array().of(select()).nullable();

/**
 * This function is used to get the required multi select schema
 * @param i18n I18n instance
 * @returns The required multi select schema
 */
export const multiSelectRequired = (i18n: I18n) =>
    multiSelect()
        .required(i18n._('error.fieldRequired'))
        .min(1, i18n._('error.fieldRequired'));
