import { I18n } from '@lingui/core';

import yup from './yup-extended';

export const generalExpensesSchema = (i18n: I18n) =>
    yup.object().shape({
        amount: yup.number().fromMoney().emptyAsUndefined().nullable(),
        frequency: yup.string().emptyAsUndefined().nullable(),
        ratio: yup
            .number()
            .emptyAsUndefined()
            .nullable()
            .min(0, i18n._({ id: 'error.percentageLessThan' }))
            .max(100, i18n._({ id: 'error.percentageGreaterThan' })),
    });
