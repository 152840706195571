import { useEffect, useMemo } from 'react';

import { useTenantSettings } from '@nestoca/multi-tenant';
import { Flex, Grid } from '@nestoca/ui';
import cn from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';
import * as R from 'ramda';
import { useFormContext } from 'react-hook-form';
import { constSelector, useRecoilValue } from 'recoil';

import { EditableCell } from 'components/editable';
import {
    BridgeLoanInformation,
    CondoFees,
    HeatingFees,
    GeneralExpenses,
    MortgageInformation,
    RentalIncomeOffset,
    Taxes,
} from 'components/owned-properties/property-detail-section';
import { DateWithPopper } from 'components/popper/date-popper';
import { HandleCheckboxToggle } from 'components/scenarios/components/checkbox';
import {
    AFTER_TRANSACTION_PURPOSE_OPTIONS,
    CURRENT_SALE_STATUS,
} from 'constants/appConstants';
import { ProblemType } from 'constants/problem';
import {
    OTHER_PROPERTY_TYPE_OPTIONS,
    useOtherPropertyPurposeOptions,
} from 'constants/property';
import { useI18n } from 'providers/i18n/use-i18n';
import { getProblemsBySection, getApplication } from 'store/applications';
import { type OtherProperty } from 'types/property';
import { formatMoney, isEmpty, booleanNormalizer } from 'utils';
import { useProblems } from 'utils/use-problems';
import { compareForId } from 'utils/validations/comparators';

import styles from './section.module.scss';

type OwnedPropertyFieldsProps = {
    isEditing: boolean;
    property: OtherProperty;
    isCreating?: boolean;
    onToggle?: HandleCheckboxToggle;
    applicantId?: number;
    applicationId?: number;
};

export const normalizeHasMortgage = R.evolve({
    hasMortgageWatch: booleanNormalizer,
});

export const PropertyDetail = ({
    isCreating,
    isEditing,
    property,
    onToggle,
    applicantId,
    applicationId,
}: OwnedPropertyFieldsProps) => {
    const methods = useFormContext();
    const { i18n } = useI18n();
    const { showValidationProblems } = useFlags();
    const application = useRecoilValue(
        applicationId ? getApplication(applicationId) : constSelector(null)
    );

    let { afterTransactionPurpose, hasMortgage, type, condoFees } = property;

    const { currentPurpose, currentSaleStatus, sellingDate } = property;

    let bridgeLoanRequired =
        property.bridgeLoanAmount || property.bridgeLoanRate ? true : false;

    const sectionProblems = useRecoilValue(
        getProblemsBySection({
            applicationId: applicationId || 0,
            section: ProblemType.properties,
        })
    );

    const {
        enableBridgeLoan: {
            settings: { enableBridgeLoanInformation },
        },
        applicantSettings: {
            settings: {
                validation: {
                    ownedProperties: {
                        mortgages: { enabled: mortgageSectionEnabled },
                        optionalFields,
                    },
                },
            },
        },
        generalExpenses: { value: enableGeneralExpenses },
        ownedProperties: {
            settings: {
                propertyDetails: { mappedSellingPrice },
            },
        },
    } = useTenantSettings();

    const mappedSellingPriceValue = property?.[mappedSellingPrice];

    useProblems(
        showValidationProblems ? sectionProblems : [],
        applicantId,
        property
    );

    if (methods) {
        const { watch } = methods;

        const [
            typeWatch,
            afterTransactionPurposeWatch,
            condoFeesWatch,
            bridgeLoanRequireWatch,
            hasMortgageWatch,
        ] = watch(
            [
                'type',
                'afterTransactionPurpose',
                'condoFees',
                'bridgeLoanRequired',
                'hasMortgage',
            ],
            {
                type,
                afterTransactionPurpose,
                condoFees,
                bridgeLoanRequired,
                hasMortgage,
            }
        );

        type = typeWatch;
        afterTransactionPurpose = afterTransactionPurposeWatch;
        condoFees = condoFeesWatch;
        bridgeLoanRequired = bridgeLoanRequireWatch;
        hasMortgage = hasMortgageWatch;
    }

    const {
        isSold,
        isRental,
        isCondo,
        isHeatingRequired,
        isBridgeLoanRequired,
        showMortgageDetails,
    } = useMemo(
        () => ({
            isSold: afterTransactionPurpose === 'SOLD',
            isRental:
                afterTransactionPurpose === 'OWNER_OCCUPIED_AND_RENTAL' ||
                afterTransactionPurpose === 'RENTAL',
            isCondo: type === 'CONDO',
            isHeatingRequired:
                type === 'CONDO' &&
                !isEmpty(condoFees?.heatingIncluded) &&
                booleanNormalizer(condoFees?.heatingIncluded) === false,
            isBridgeLoanRequired:
                booleanNormalizer(bridgeLoanRequired) === true,
            showMortgageDetails:
                booleanNormalizer(hasMortgage) === true &&
                mortgageSectionEnabled,
        }),
        [
            afterTransactionPurpose,
            type,
            condoFees?.heatingIncluded,
            bridgeLoanRequired,
            hasMortgage,
            mortgageSectionEnabled,
        ]
    );

    useEffect(() => {
        /* If after transaction is not sold we want to unregister theses fields */
        if (!isSold) {
            methods?.unregister([
                'bridgeLoanRequired',
                'bridgeLoanNumber',
                'bridgeLoanType',
            ]);
        }
    }, [methods?.unregister, isSold]);

    const optionalOwnedPropertyFields = optionalFields || [];

    const otherPropertyPurposeOptions = useOtherPropertyPurposeOptions();

    return (
        <Flex className={styles.section} direction="column" gap={4}>
            <Grid
                className={cn(styles['grid'], styles['grid--with-divider'], {
                    [styles['grid--auto-fit']]: isCreating,
                })}
                gap={4}
            >
                <EditableCell
                    isEditing={isEditing}
                    name="currentPurpose"
                    fieldType="select"
                    label="purpose"
                    options={otherPropertyPurposeOptions}
                    value={currentPurpose}
                    required
                    isFieldInvalidCompareFn={compareForId(property)}
                    problemType={ProblemType.properties}
                />

                <EditableCell
                    isEditing={isEditing}
                    name="afterTransactionPurpose"
                    fieldType="select"
                    options={AFTER_TRANSACTION_PURPOSE_OPTIONS(i18n)}
                    label="afterTransactionPurpose"
                    value={afterTransactionPurpose}
                    required
                    isFieldInvalidCompareFn={compareForId(property)}
                    problemType={ProblemType.properties}
                />
                {!isSold && (
                    <>
                        <EditableCell
                            isEditing={isEditing}
                            name="type"
                            fieldType="select"
                            options={OTHER_PROPERTY_TYPE_OPTIONS(i18n)}
                            label="propertyType"
                            value={type}
                            isFieldInvalidCompareFn={compareForId(property)}
                            problemType={ProblemType.properties}
                        />

                        <EditableCell
                            isEditing={isEditing}
                            name="estimatedPropertyValue"
                            fieldType="money"
                            label={i18n._(`estimatedPropertyValue`)}
                            value={formatMoney(
                                property?.estimatedPropertyValue
                            )}
                            required
                            isFieldInvalidCompareFn={compareForId(property)}
                            problemType={ProblemType.properties}
                        />
                    </>
                )}
                {isSold && (
                    <>
                        <EditableCell
                            isEditing={isEditing}
                            name="currentSaleStatus"
                            fieldType="select"
                            options={CURRENT_SALE_STATUS(i18n)}
                            label="saleStatus"
                            value={currentSaleStatus}
                            css={{ alignSelf: 'end' }}
                            required={
                                !optionalOwnedPropertyFields.includes(
                                    'currentSaleStatus'
                                )
                            }
                            isFieldInvalidCompareFn={compareForId(property)}
                            problemType={ProblemType.properties}
                        />
                        <EditableCell
                            isEditing={isEditing}
                            name={mappedSellingPrice}
                            fieldType="money"
                            label={'sellingPrice'}
                            value={formatMoney(mappedSellingPriceValue)}
                            css={{ alignSelf: 'end' }}
                            required
                            isFieldInvalidCompareFn={compareForId(property)}
                            problemType={ProblemType.properties}
                        />

                        <DateWithPopper
                            isEditing={isEditing}
                            name="sellingDate"
                            label="expectToBeSoldDate"
                            value={sellingDate !== null ? sellingDate : ''}
                            required={
                                !optionalOwnedPropertyFields.includes(
                                    'sellingDate'
                                )
                            }
                            placement="left"
                            isFieldInvalidCompareFn={compareForId(property)}
                            problemType={ProblemType.properties}
                        />
                    </>
                )}
            </Grid>
            {isSold && enableBridgeLoanInformation && (
                <BridgeLoanInformation
                    isCreating={isCreating}
                    isEditing={isEditing}
                    isBridgeLoanRequired={isBridgeLoanRequired}
                    bridgeLoanNumber={application?.bridgeLoanNumber || ''}
                    showMortgageDetails={showMortgageDetails}
                    property={property}
                />
            )}
            {!isSold && (
                <>
                    <Taxes
                        isCreating={isCreating}
                        isEditing={isEditing}
                        property={property}
                        onCheckboxToggle={onToggle}
                    />
                    {isRental && (
                        <RentalIncomeOffset
                            isCreating={isCreating}
                            isEditing={isEditing}
                            property={property}
                            onCheckboxToggle={onToggle}
                        />
                    )}
                    <CondoFees
                        isCreating={isCreating}
                        isEditing={isEditing}
                        condoFees={condoFees}
                        property={property}
                        onCheckboxToggle={onToggle}
                    />
                    <HeatingFees
                        isCreating={isCreating}
                        isEditing={isEditing}
                        isCondo={isCondo}
                        isHeatingRequired={isHeatingRequired}
                        heatingIncluded={condoFees?.heatingIncluded}
                        onCheckboxToggle={onToggle}
                        property={property}
                    />
                    {enableGeneralExpenses && (
                        <GeneralExpenses
                            isEditing={isEditing}
                            generalExpenses={property?.generalExpenses}
                        />
                    )}
                </>
            )}
            <MortgageInformation
                isCreating={isCreating}
                isEditing={isEditing}
                isBridgeLoanRequired={isBridgeLoanRequired}
                hasMortgage={hasMortgage}
                showMortgageDetails={showMortgageDetails}
                property={property}
            />
        </Flex>
    );
};
