/* eslint-disable max-lines */
import React from 'react';

import {
    CoApplicantSearchModal,
    ConnectAccountsModal,
    ConnectAccountsModalProps,
} from 'components/account-merge';
import coApplicantSearchModalStyles from 'components/account-merge/co-applicant-search-modal/co-applicant-search-modal.module.scss';
import connectAccountModalStyles from 'components/account-merge/connect-accounts-modal/connect-accounts-modal.module.scss';
import { ApplicantModal } from 'components/applicant/applicant-modal';
import { ApplicationReviewSummaryPDFModal } from 'components/application-review/application-review-summary-pdf-modal';
import { FrequencyCompareModal } from 'components/application-review/section/mortgage-details/frequency-compare-modal/frequency-compare-modal';
import { MortgageModal } from 'components/application-review/section/mortgage-modal';
import { AssetModal } from 'components/asset/asset-modal';
import { ChangeApplicationType } from 'components/change-application-type-modal/change-application-type-modal';
import { CreditReportPDFModal } from 'components/credit-report-message/credit-report-pdf-modal';
import { EidModal } from 'components/credit-report-message/eid-modal';
import { DashboardEmploymentModal } from 'components/dashboard-header/components/dashboard-employment-modal';
import { DownPaymentModal } from 'components/downpayment-modal/downpayment-modal';
import { ErrorsModal } from 'components/editable/errors-modal';
import { EmploymentModal } from 'components/employment/employment-modal';
import {
    DeleteLiabilitiesModal,
    LiabilityModal,
} from 'components/liabilities/modal';
import { LockApplicationModal } from 'components/lock-application-modal/lock-application-modal';
import { Modal } from 'components/modal';
import { OtherIncomeModal } from 'components/other-income/other-income-modal';
import { OwnedPropertiesModal } from 'components/owned-properties/owned-properties-modal';
import {
    PlaidMonitoringHitsModal,
    PlaidMonitoringHitsModalProps,
} from 'components/plaid-monitoring-card/plaid-monitoring-hits-modal';
import plaidMonitoringHitsModalStyles from 'components/plaid-monitoring-card/plaid-monitoring-hits-modal/plaid-monitoring-hits-modal.module.scss';
import { RegisterAddressModal } from 'components/register-address/register-address-modal';
import { PullCredit } from 'components/scenarios/components/pull-credit-modal';
import { ScenarioModal } from 'components/scenarios/scenario-modal';
import {
    PropertyLocationModal,
    PropertyLocationModalProps,
} from 'components/subject-property/property-location-modal';
import propertyLocationModalStyles from 'components/subject-property/property-location-modal/property-location-modal.module.scss';
import { RejectionModal } from 'components/underwriter-flow/rejection-modal';
import { useI18n } from 'providers/i18n/use-i18n';
import { useModal } from 'providers/modals/use-modal';
import { useEditingContext } from 'utils/use-editing-context';

import type { ApplicantModalProps } from 'components/applicant/applicant-modal';
import type { ApplicationReviewSummaryPDFModalProps } from 'components/application-review/application-review-summary-pdf-modal';
import type { MortgageModalProps } from 'components/application-review/section/mortgage-modal';
import type { AssetModalProps } from 'components/asset/asset-modal';
import type { ChangeApplicationTypeModalProps } from 'components/change-application-type-modal/change-application-type-modal';
import type { CreditReportPDFModalProps } from 'components/credit-report-message/credit-report-pdf-modal';
import type { EidModalProps } from 'components/credit-report-message/eid-modal';
import type { DownPaymentModalProps } from 'components/downpayment-modal/downpayment-modal';
import type { ErrorsModalProps } from 'components/editable/errors-modal';
import type { EmploymentModalProps } from 'components/employment/employment-modal';
import type {
    DeleteLiabilitiesModalProps,
    LiabilityModalProps,
} from 'components/liabilities/modal';
import type { LockApplicationProps } from 'components/lock-application-modal/lock-application-modal';
import type { OtherIncomeModalProps } from 'components/other-income/other-income-modal';
import type { OwnedPropertiesProps } from 'components/owned-properties/owned-properties-modal';
import type { RegisterAddressModalProps } from 'components/register-address/register-address-modal';
import type { PullCreditModalProps } from 'components/scenarios/components/pull-credit-modal';
import type { ScenarioModalProps } from 'components/scenarios/scenario-modal';
import type { RejectionModalProps } from 'components/underwriter-flow/rejection-modal';

type Props = {
    children: React.ReactNode;
};

export const EntitiesModalWrapper = ({ children }: Props) => {
    const { i18n } = useI18n();
    const { clearEditingKey } = useEditingContext();
    const {
        isVisible: isCreateRegisterAddressModalVisible,
        close: closeCreateRegisterAddressModal,
        props: createRegisterAddressModalProps,
    } = useModal<RegisterAddressModalProps>('createRegisterAddress');

    const {
        isVisible: isCreateApplicantModalVisible,
        close: closeCreateApplicantModal,
        props: createApplicantModalProps,
    } = useModal<ApplicantModalProps>('createApplicant');

    const {
        isVisible: isApplicationReviewSummaryPDFModalVisible,
        close: closeApplicationReviewSummaryPDFModal,
        props: applicationReviewSummaryPDFModalProps,
    } = useModal<ApplicationReviewSummaryPDFModalProps>(
        'applicationReviewSummaryPDFModal'
    );

    const {
        isVisible: isCreateAssetModalVisible,
        close: closeCreateAssetModal,
        props: createAssetModalProps,
    } = useModal<AssetModalProps>('createAsset');

    const {
        isVisible: isCreateOtherIncomeModalVisible,
        close: closeCreateOtherIncomeModal,
        props: createOtherIncomeModalProps,
    } = useModal<OtherIncomeModalProps>('createOtherIncome');

    const {
        isVisible: isCreateOwnedPropertyModalVisible,
        close: closeCreateOwnedPropertyModal,
        props: createOwnedPropertyModalProps,
    } = useModal<OwnedPropertiesProps>('createOwnedProperty');

    const {
        isVisible: isCreateEmploymentModalVisible,
        close: closeCreateEmploymentModal,
        props: createEmploymentModalProps,
    } = useModal<EmploymentModalProps>('createEmployment');

    const {
        isVisible: isCreateLiabilityModalVisible,
        close: closeCreateLiabilityModal,
        props: createLiabilityModalProps,
    } = useModal<LiabilityModalProps>('createLiability');

    const {
        isVisible: isDeleteLiabilitiesModalVisible,
        close: closeDeleteLiabilitiesModal,
        props: deleteLiabilitiesModalProps,
    } = useModal<DeleteLiabilitiesModalProps>('deleteLiabilities');

    const {
        isVisible: isScenarioModalVisible,
        close: closeScenarioModal,
        props: scenarioModalProps,
    } = useModal<ScenarioModalProps>('scenariosDetails');

    const {
        isVisible: isErrorsModalVisible,
        close: closeErrorsModal,
        props: errorsModalProps,
    } = useModal<ErrorsModalProps>('errorsDetails');

    const {
        isVisible: isMortgageModalVisible,
        close: closeMortgageModal,
        props: mortgageModalProps,
    } = useModal<MortgageModalProps>('mortgageDetails');

    const {
        isVisible: isDashboardEmploymentModalVisible,
        close: closeDashboardEmploymentModal,
        props: dashboardEmploymentModalProps,
    } = useModal('dashboardEmploymentDetails');

    const {
        isVisible: isPullCreditModalVisible,
        close: closePullCreditModal,
        props: pullCreditModalProps,
    } = useModal<PullCreditModalProps>('pullCredit');

    const {
        isVisible: isEidModalVisible,
        close: closeEidCreditModal,
        props: eidModalProps,
    } = useModal<EidModalProps>('eIdModal');

    const {
        isVisible: isCreditReportPDFModalVisible,
        close: closeCreditReportPDFModal,
        props: creditReportPDFModalProps,
    } = useModal<CreditReportPDFModalProps>('creditReportPDFModal');

    const {
        isVisible: isDownPaymentModalVisible,
        close: closeDownPaymentModal,
        props: downPaymentModalProps,
    } = useModal<DownPaymentModalProps>('downPaymentModal');

    const {
        isVisible: isLockApplicationModalVisible,
        close: closeLockApplicationModal,
        props: lockApplicationModalProps,
    } = useModal<LockApplicationProps>('lockApplicationModal');

    const {
        isVisible: isChangeApplicationTypeVisible,
        close: closeChangeApplicationType,
        props: changeApplicationTypeModalProps,
    } = useModal<ChangeApplicationTypeModalProps>('changeApplicationType');

    const {
        isVisible: isFrequencyCompareModalVisible,
        close: closeFrequencyCompareModal,
    } = useModal('frequencyCompareModal');

    const {
        isVisible: isRejectionModalVisible,
        close: closeRejectionModal,
        props: rejectionModalProps,
    } = useModal<RejectionModalProps>('rejectionModal');

    const {
        isVisible: isPlaidMonitoringHitsModalVisible,
        close: closePlaidMonitoringHitsModal,
        props: plaidMonitoringHitsModalProps,
    } = useModal<PlaidMonitoringHitsModalProps>('plaid-monitoring-hits-modal');

    const {
        isVisible: isPropertyLocationModalVisible,
        close: closePropertyLocationModal,
        props: propertyLocationModalProps,
    } = useModal<PropertyLocationModalProps>('property-location-modal');

    const {
        isVisible: isCoApplicantSearchModalVisible,
        close: closeCoApplicantSearchModal,
    } = useModal('co-applicant-search-modal');

    const {
        isVisible: isConnectAccountsModalVisible,
        close: closeConnectAccountsModal,
        props: connectAccountsModalProps,
    } = useModal<ConnectAccountsModalProps>('connect-accounts-modal');

    return (
        <>
            {children}
            <Modal
                id="connect-accounts-modal"
                className={
                    connectAccountModalStyles['connect-accounts-modal-wrapper']
                }
                visible={isConnectAccountsModalVisible}
                onClose={() => closeConnectAccountsModal()}
                sx={{ width: ['100%'], maxWidth: 700 }}
                zIndex={900}
                closeOnEscape
            >
                <ConnectAccountsModal {...connectAccountsModalProps} />
            </Modal>
            <Modal
                id="co-applicant-search-modal"
                className={
                    coApplicantSearchModalStyles[
                        'co-applicant-search-modal-wrapper'
                    ]
                }
                visible={isCoApplicantSearchModalVisible}
                onClose={() => closeCoApplicantSearchModal()}
                sx={{ width: ['100%'], maxWidth: 700 }}
                zIndex={900}
                closeOnEscape
            >
                <CoApplicantSearchModal />
            </Modal>
            <Modal
                id="property-location-modal"
                className={
                    propertyLocationModalStyles['property-location-modal-panel']
                }
                visible={isPropertyLocationModalVisible}
                zIndex={900}
                sx={{ width: ['100%'], maxWidth: 1033 }}
                onClose={closePropertyLocationModal}
            >
                <PropertyLocationModal {...propertyLocationModalProps} />
            </Modal>
            <Modal
                id="plaid-monitoring-hits-modal"
                className={
                    plaidMonitoringHitsModalStyles[
                        'plaid-monitoring-hits-modal-panel'
                    ]
                }
                visible={isPlaidMonitoringHitsModalVisible}
                zIndex={900}
                sx={{ width: ['100%'], maxWidth: 1033 }}
                onClose={closePlaidMonitoringHitsModal}
            >
                <PlaidMonitoringHitsModal {...plaidMonitoringHitsModalProps} />
            </Modal>
            <Modal
                id="create-applicant"
                visible={isCreateApplicantModalVisible}
                zIndex={900}
                sx={{ width: ['100%'], maxWidth: 640 }}
                title={i18n._('createNewCoApplicant')}
                onClose={() => {
                    closeCreateApplicantModal();
                    clearEditingKey();
                }}
            >
                <ApplicantModal {...createApplicantModalProps} />
            </Modal>
            <Modal
                id="application-review-summary-pdf-modal"
                visible={isApplicationReviewSummaryPDFModalVisible}
                zIndex={900}
                sx={{ width: ['100%'], maxWidth: 940 }}
                title={i18n._('applicationReview')}
                onClose={() => {
                    closeApplicationReviewSummaryPDFModal();
                }}
            >
                <ApplicationReviewSummaryPDFModal
                    {...applicationReviewSummaryPDFModalProps}
                />
            </Modal>
            <Modal
                id="create-employment"
                visible={isCreateEmploymentModalVisible}
                zIndex={900}
                sx={{ width: ['100%'], maxWidth: 1033 }}
                title={i18n._('createNewIncome')}
                onClose={() => {
                    closeCreateEmploymentModal();
                    clearEditingKey();
                }}
            >
                <EmploymentModal {...createEmploymentModalProps} />
            </Modal>
            <Modal
                id="create-asset"
                visible={isCreateAssetModalVisible}
                zIndex={900}
                sx={{ width: ['100%'], maxWidth: 750 }}
                title={i18n._('createNewAsset')}
                onClose={() => {
                    closeCreateAssetModal();
                    clearEditingKey();
                }}
            >
                <AssetModal {...createAssetModalProps} />
            </Modal>
            <Modal
                id="create-other-income"
                visible={isCreateOtherIncomeModalVisible}
                zIndex={900}
                sx={{ width: ['100%'], maxWidth: 750 }}
                title={i18n._('createNewOtherIncome')}
                onClose={() => {
                    closeCreateOtherIncomeModal();
                    clearEditingKey();
                }}
            >
                <OtherIncomeModal {...createOtherIncomeModalProps} />
            </Modal>
            <Modal
                id="create-owned-properties"
                visible={isCreateOwnedPropertyModalVisible}
                zIndex={900}
                sx={{ width: ['100%'], maxWidth: 1033 }}
                title={i18n._('createNewOwnedProperties')}
                onClose={() => {
                    closeCreateOwnedPropertyModal();
                    clearEditingKey();
                }}
            >
                <OwnedPropertiesModal {...createOwnedPropertyModalProps} />
            </Modal>
            <Modal
                id="create-register-address"
                visible={isCreateRegisterAddressModalVisible}
                zIndex={900}
                sx={{ width: ['100%'], maxWidth: 1033 }}
                title={i18n._('createNewAddress')}
                onClose={() => {
                    closeCreateRegisterAddressModal();
                    clearEditingKey();
                }}
            >
                <RegisterAddressModal {...createRegisterAddressModalProps} />
            </Modal>
            <Modal
                id="create-liability"
                visible={isCreateLiabilityModalVisible}
                zIndex={900}
                sx={{ width: ['100%'], maxWidth: 750 }}
                title={i18n._('createNewLiability')}
                onClose={() => {
                    closeCreateLiabilityModal();
                    clearEditingKey();
                }}
            >
                <LiabilityModal {...createLiabilityModalProps} />
            </Modal>
            <Modal
                id="delete-liabilities"
                visible={isDeleteLiabilitiesModalVisible}
                zIndex={900}
                sx={{ width: ['100%'], maxWidth: 840 }}
                title={i18n._('deleteLiabilities')}
                onClose={() => {
                    closeDeleteLiabilitiesModal();
                    clearEditingKey();
                }}
            >
                <DeleteLiabilitiesModal {...deleteLiabilitiesModalProps} />
            </Modal>
            <Modal
                id="ratio-calculator"
                visible={isScenarioModalVisible}
                zIndex={900}
                sx={{ width: ['100%'], maxWidth: 800 }}
                title={i18n._('ratioCalculator')}
                onClose={() => {
                    closeScenarioModal();
                    clearEditingKey();
                }}
                closeOnEscape
            >
                <ScenarioModal {...scenarioModalProps} />
            </Modal>
            <Modal
                id="errors"
                visible={isErrorsModalVisible}
                zIndex={900}
                sx={{ width: ['100%'], maxWidth: 640 }}
                title={i18n._('errorsTitle')}
                onClose={() => {
                    closeErrorsModal();
                }}
                variant="error"
            >
                <ErrorsModal {...errorsModalProps} />
            </Modal>

            <Modal
                id="mortgage"
                visible={isMortgageModalVisible}
                zIndex={900}
                sx={{ width: ['100%'], maxWidth: 940 }}
                title={i18n._('paymentAnalyzer')}
                onClose={() => {
                    closeMortgageModal();
                }}
            >
                <MortgageModal {...mortgageModalProps} />
            </Modal>

            <Modal
                id="dashboard-employment"
                visible={isDashboardEmploymentModalVisible}
                zIndex={900}
                sx={{ width: ['100%'], maxWidth: 940 }}
                title={i18n._('incomeDetailsModal.title')}
                onClose={() => {
                    closeDashboardEmploymentModal();
                }}
                closeOnEscape
            >
                <DashboardEmploymentModal {...dashboardEmploymentModalProps} />
            </Modal>

            <Modal
                id="pull-credit"
                visible={isPullCreditModalVisible}
                zIndex={900}
                sx={{ width: ['100%'], maxWidth: 940 }}
                onClose={() => {
                    closePullCreditModal();
                }}
                closeOnEscape
            >
                <PullCredit {...pullCreditModalProps} />
            </Modal>

            <Modal
                id="eid"
                visible={isEidModalVisible}
                zIndex={900}
                sx={{ width: ['100%'], maxWidth: 940 }}
                title={i18n._('eid.modal.title')}
                onClose={() => {
                    closeEidCreditModal();
                }}
            >
                <EidModal {...eidModalProps} />
            </Modal>

            <Modal
                id="credit-report-pdf"
                visible={isCreditReportPDFModalVisible}
                zIndex={900}
                sx={{ width: ['100%'], maxWidth: 940 }}
                title={i18n._('creditReportPDF.modal.title')}
                onClose={() => {
                    closeCreditReportPDFModal();
                }}
            >
                <CreditReportPDFModal {...creditReportPDFModalProps} />
            </Modal>

            <Modal
                id="down-payment"
                visible={isDownPaymentModalVisible}
                zIndex={900}
                sx={{ width: ['100%'], maxWidth: 940 }}
                title={i18n._('downPaymentChangingOccupancyMessage.title')}
                onClose={() => {
                    closeDownPaymentModal();
                }}
            >
                <DownPaymentModal {...downPaymentModalProps} />
            </Modal>

            <Modal
                id="lock-application"
                visible={isLockApplicationModalVisible}
                zIndex={900}
                sx={{ width: ['100%'], maxWidth: 940 }}
                title={i18n._('lockApplication.title')}
                onClose={() => {
                    closeLockApplicationModal();
                }}
            >
                <LockApplicationModal {...lockApplicationModalProps} />
            </Modal>

            <Modal
                id="change-application-type"
                visible={isChangeApplicationTypeVisible}
                zIndex={900}
                sx={{ width: ['100%'], maxWidth: 940 }}
                title={i18n._('changeApplication.title')}
                onClose={() => {
                    closeChangeApplicationType();
                }}
            >
                <ChangeApplicationType {...changeApplicationTypeModalProps} />
            </Modal>

            <Modal
                id="frequency-compare"
                visible={isFrequencyCompareModalVisible}
                zIndex={900}
                sx={{ width: ['100%'], maxWidth: 940 }}
                title={i18n._('frequencyCompare.title')}
                onClose={() => {
                    closeFrequencyCompareModal();
                }}
            >
                <FrequencyCompareModal />
            </Modal>
            <Modal
                id="rejection"
                visible={isRejectionModalVisible}
                zIndex={900}
                sx={{ width: ['100%'], maxWidth: 760 }}
                onClose={() => {
                    closeRejectionModal();
                }}
            >
                <RejectionModal {...rejectionModalProps} />
            </Modal>
        </>
    );
};
