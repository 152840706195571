import { useTenantSettings } from '@nestoca/multi-tenant';
import { LiabilityPayOffTypes } from '@nestoca/multi-tenant';

import { useI18n } from 'providers/i18n/use-i18n';

type LiabilityPayOff = typeof LiabilityPayOffTypes;

type LiabilityPayOffValue = LiabilityPayOff[keyof LiabilityPayOff];

type LiabilityPayOffValueMap = Record<
    LiabilityPayOffValue,
    {
        id: LiabilityPayOffValue;
        labelKey: string;
    }
>;

//@ts-expect-error missing types from fe-shared, added in OG-11167
export const LIABILITY_PAYOFF_MAP: LiabilityPayOffValueMap = {
    [LiabilityPayOffTypes.INTERNAL_FUNDS]: {
        id: LiabilityPayOffTypes.INTERNAL_FUNDS,
        labelKey: 'INTERNAL_FUNDS',
    },
    [LiabilityPayOffTypes.FROM_PROCEEDS]: {
        id: LiabilityPayOffTypes.FROM_PROCEEDS,
        labelKey: 'FROM_PROCEEDS',
    },
    [LiabilityPayOffTypes.FROM_SALE_PROCEEDS]: {
        id: LiabilityPayOffTypes.FROM_SALE_PROCEEDS,
        labelKey: 'FROM_SALE_PROCEEDS',
    },
    [LiabilityPayOffTypes.PRIOR_TO_ADVANCE]: {
        id: LiabilityPayOffTypes.PRIOR_TO_ADVANCE,
        labelKey: 'PRIOR_TO_ADVANCE',
    },
    [LiabilityPayOffTypes.FROM_PROCEEDS_AND_CLOSE]: {
        id: LiabilityPayOffTypes.FROM_PROCEEDS_AND_CLOSE,
        labelKey: 'FROM_PROCEEDS_AND_CLOSE',
    },
    [LiabilityPayOffTypes.PRIOR_TO_ADVANCE_AND_CLOSE]: {
        id: LiabilityPayOffTypes.PRIOR_TO_ADVANCE_AND_CLOSE,
        labelKey: 'PRIOR_TO_ADVANCE_AND_CLOSE',
    },
    [LiabilityPayOffTypes.PARTIAL_PAYDOWN]: {
        id: LiabilityPayOffTypes.PARTIAL_PAYDOWN,
        labelKey: 'PARTIAL_PAYDOWN',
    },
    [LiabilityPayOffTypes.NOT_APPLICABLE]: {
        id: LiabilityPayOffTypes.NOT_APPLICABLE,
        labelKey: 'NOT_APPLICABLE',
    },
};

export const useLiabilityPayOffOptions = () => {
    const { i18n } = useI18n();

    const {
        typesOverride: {
            settings: { liabilityPayOffTypes },
        },
    } = useTenantSettings();

    return liabilityPayOffTypes
        .filter((typeId) => !!LIABILITY_PAYOFF_MAP[typeId])
        .map((typeId) => ({
            value: typeId,
            label: i18n._(LIABILITY_PAYOFF_MAP[typeId].labelKey),
        }));
};
