import React from 'react';

import { ErrorMessage } from '@hookform/error-message';
import { yupResolver } from '@hookform/resolvers/yup';
import { I18n } from '@lingui/core';
import {
    Button,
    Card,
    Flex,
    FormElement,
    Heading,
    ReactSelect,
    ErrorMessage as ErrorMessageComponent,
    Box,
    Typography,
} from '@nestoca/ui';
import { useRouter } from 'next/router';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { SingleValue } from 'react-select';
import { toast } from 'react-toastify';
import { useSetRecoilState } from 'recoil';

import { SuccessfulToastContent } from 'components/successful-toast-content';
import {
    TransactionValue,
    useTransactionTypeOptions,
} from 'constants/transaction-types';
import { client as apiClient } from 'libs/api';
import { useClient } from 'providers/auth0';
import { useI18n } from 'providers/i18n/use-i18n';
import {
    currentApplicationIdState,
    useRefreshApplications,
} from 'store/applications';
import { ReactSelectOption } from 'types/general';
import { selectRequired } from 'validations/select';
import yup from 'validations/yup-extended';

import styles from './create-application.module.scss';

type CreateApplicationFormValues = {
    applicationType: SingleValue<ReactSelectOption<TransactionValue>> | null;
};

const validationSchema = (i18n: I18n) =>
    yup.object().shape({
        applicationType: selectRequired(i18n),
    });

type CreateApplicationFormProps = { redirect?: boolean };

const CreateApplicationForm: React.FunctionComponent<
    CreateApplicationFormProps
> = ({ redirect }) => {
    const { i18n } = useI18n();

    const { client } = useClient();

    const { push } = useRouter();

    const transactionTypeOptions = useTransactionTypeOptions();

    const setCurrentApplicationIdState = useSetRecoilState(
        currentApplicationIdState
    );

    const refreshApplications = useRefreshApplications();

    const { formState, control, ...methods } =
        useForm<CreateApplicationFormValues>({
            defaultValues: {
                applicationType: null,
            },
            resolver: yupResolver(validationSchema(i18n)),
        });

    const { isSubmitting, errors } = formState;

    const onSubmit = async (values: CreateApplicationFormValues) => {
        try {
            if (!values.applicationType?.value) {
                toast.error(
                    <Typography size={0}>
                        {i18n._('createApplication.error.emptyType')}
                    </Typography>,
                    {
                        autoClose: 5000,
                        closeButton: false,
                        closeOnClick: true,
                    }
                );
                return;
            }

            const { data } = await apiClient.createApplication(
                values.applicationType?.value,
                client?.subPartnerId || 0
            );

            await refreshApplications();

            toast.success(
                <SuccessfulToastContent text={i18n._('successfullySaved')} />,
                {
                    autoClose: 5000,
                    closeButton: false,
                    closeOnClick: true,
                }
            );

            if (redirect && data?.id) {
                setCurrentApplicationIdState(data.id);

                push(`/applications/${data.id}`);
                return;
            }
        } catch (error) {
            toast.error(
                <Typography size={0}>{i18n._('failedToSave')}</Typography>,
                {
                    autoClose: 5000,
                    closeButton: false,
                    closeOnClick: true,
                }
            );
        }
    };

    return (
        <Card className={styles['create-application-card']}>
            <FormProvider formState={formState} control={control} {...methods}>
                <Flex
                    direction="column"
                    gap={4}
                    as="form"
                    onSubmit={methods.handleSubmit(onSubmit)}
                >
                    <Heading size={5} weight={6}>
                        {i18n._('createApplication.title')}
                    </Heading>

                    <FormElement>
                        <Controller
                            name="applicationType"
                            control={control}
                            render={({ field, fieldState }) => (
                                <ReactSelect
                                    isClearable
                                    isDirty={fieldState.isDirty}
                                    size="large"
                                    placeholder={i18n._(
                                        'createApplication.selectTypeLabel'
                                    )}
                                    menuPosition="fixed"
                                    error={!!errors.applicationType}
                                    options={transactionTypeOptions}
                                    {...field}
                                />
                            )}
                        />
                        <ErrorMessage
                            name="applicationType"
                            render={({ message }) => (
                                <ErrorMessageComponent>
                                    {message}
                                </ErrorMessageComponent>
                            )}
                        ></ErrorMessage>
                    </FormElement>
                    <Box>
                        <Button
                            type="submit"
                            isLoading={isSubmitting}
                            disabled={isSubmitting}
                            e2ePrefix="create-application-submit"
                        >
                            {i18n._('createApplication.submit')}
                        </Button>
                    </Box>
                </Flex>
            </FormProvider>
        </Card>
    );
};

export default CreateApplicationForm;
