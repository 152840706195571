import { I18n } from '@lingui/core';
import { OwnedPropertyValidationField } from '@nestoca/multi-tenant';

import { ApplicationType } from 'types/application';
import { booleanNormalizer, isEmpty, numberNormalizer } from 'utils';

import { getAddressSchema } from './address';
import { getAmountFrequencyRequiredSchema } from './amount-frequency';
import { booleanRequired } from './boolean';
import { generalExpensesSchema } from './general-expenses';
import { moneyRequired, numberRequired } from './number';
import { stringRequired } from './string';
import yup from './yup-extended';

import type { OwnedProperties } from '@nestoca/multi-tenant';
import type {
    CoOwnershipFees,
    OtherPropertyType,
    PurposeAfterTransaction,
} from 'types/property';
import type { BaseSchema } from 'yup';

export const getOtherPropertyPartialModelSchema = (i18n: I18n) =>
    yup
        .object()
        .shape({
            applicantId: stringRequired(i18n),
            type: stringRequiredIfNotSold(i18n),
        })
        .concat(getOtherPropertyPartialSchema(i18n));

export const getOtherPropertyRentalIncomeSchema = (i18n: I18n) =>
    yup.object().when('afterTransactionPurpose', {
        is: (afterTransactionPurpose: PurposeAfterTransaction) =>
            isRental(afterTransactionPurpose),
        then: getAmountFrequencyRequiredSchema(i18n),
        otherwise: yup.object().optional().nullable(),
    });

export const getOtherPropertyRentalIncomeRatioSchema = (i18n: I18n) =>
    yup.number().when('afterTransactionPurpose', {
        is: (afterTransactionPurpose: PurposeAfterTransaction) =>
            isRental(afterTransactionPurpose),
        then: numberRequired(i18n)
            .min(0, i18n._({ id: 'error.percentageLessThan' }))
            .max(100, i18n._({ id: 'error.percentageGreaterThan' })),
        otherwise: yup.number().emptyAsUndefined().nullable(),
    });

export const mortgageBalanceRequired = (i18n: I18n) =>
    numberRequired(i18n).min(
        0,
        i18n._({
            id: 'error.valueEqualOrGreaterThan',
            values: { value: 0 },
        })
    );

export const getMortgageSchemaIfNoMortgageAndBridgeLoanRequired = (
    i18n: I18n
) =>
    yup.object().shape({
        balance: mortgageBalanceRequired(i18n),
    });

export const getOtherPropertyMortgageSchema = (i18n: I18n) =>
    yup.object().when(
        ['hasMortgage', 'bridgeLoanRequired', '$mortgageSectionEnabled'],
        // @ts-ignore
        (
            hasMortgage: 'true' | 'false' | boolean,
            bridgeLoanRequired: 'true' | 'false' | boolean,
            mortgageSectionEnabled: boolean,
            schema: BaseSchema
        ) => {
            const isBridgeLoanRequired: boolean =
                booleanNormalizer(bridgeLoanRequired);

            const normalizedHasMortgage = booleanNormalizer(hasMortgage);

            if (mortgageSectionEnabled) {
                if (normalizedHasMortgage) {
                    return otherPropertyMortgage(i18n, isBridgeLoanRequired);
                }

                if (isBridgeLoanRequired) {
                    return getMortgageSchemaIfNoMortgageAndBridgeLoanRequired(
                        i18n
                    );
                }
            }

            return schema;
        }
    );

export const getOtherPropertyTaxesSchema = (i18n: I18n) =>
    yup.object().when(
        ['afterTransactionPurpose', 'estimatedPropertyValue'],
        // @ts-ignore
        (afterTransactionPurpose, estimatedPropertyValue, schema) => {
            const parsed = numberNormalizer(estimatedPropertyValue);

            if (parsed && parsed !== 0 && afterTransactionPurpose !== 'SOLD') {
                return yup.object().shape({
                    amount: numberRequired(i18n).fromMoney().zeroAsUndefined(),
                    year: yup.number().emptyAsUndefined().nullable(),
                });
            }
            return schema;
        }
    );

export const getOtherPropertyCondoFeesSchema = (i18n: I18n) =>
    yup.object().when('type', (type: OtherPropertyType, schema) => {
        if (type === 'CONDO') {
            return yup.object().shape(
                {
                    amount: moneyRequired(i18n).min(
                        1,
                        i18n._({ id: 'error.fieldRequired' })
                    ),
                    frequency: stringRequired(i18n),
                    heatingIncluded: booleanRequired(i18n).emptyAsUndefined(),
                    ratio: numberRequired(i18n)
                        .typeError(i18n._({ id: 'error.typeError' }))
                        .min(0, i18n._({ id: 'error.percentageLessThan' }))
                        .max(
                            100,
                            i18n._({ id: 'error.percentageGreaterThan' })
                        ),
                },
                // @ts-ignore
                ['amount', 'frequency', 'heatingIncluded']
            );
        }
        return schema;
    });

export const getOtherPropertyMortgagePaymentSchema = (i18n: I18n) =>
    yup.object().shape({
        amount: numberRequired(i18n).fromMoney().zeroAsUndefined(),
        frequency: yup.string().when('amount', {
            is: (val: number) => val,
            then: stringRequired(i18n),
            otherwise: yup.string().optional().nullable().default(''),
        }),
    });

export const getOtherPropertyMortgagePortIndicatorSchema = (i18n: I18n) =>
    yup.string().when(
        ['$type', '$portPropertyId', '$propertyId'],
        // @ts-ignore
        (
            type: ApplicationType,
            portPropertyId: number,
            propertyId: number,
            schema: BaseSchema
        ) => {
            if (type === 'PORT') {
                const otherOwnedPropertyHasPortIndicator =
                    !!portPropertyId && portPropertyId !== propertyId;

                return otherOwnedPropertyHasPortIndicator
                    ? schema
                          .test({
                              name: 'Port Indicator already exists',
                              test: (portIndicator: string) =>
                                  !booleanNormalizer(portIndicator),
                              message: i18n._('error.portIndicator'),
                          })
                          .required(i18n._('error.fieldRequired'))
                    : schema.required(i18n._('error.fieldRequired'));
            }

            return schema.optional().nullable();
        }
    );

export const isRental = (afterTransactionPurpose: PurposeAfterTransaction) =>
    afterTransactionPurpose === 'OWNER_OCCUPIED_AND_RENTAL' ||
    afterTransactionPurpose === 'RENTAL';

export const isSold = (afterTransactionPurpose: PurposeAfterTransaction) =>
    afterTransactionPurpose === 'SOLD';

export const getOtherPropertyPartialSchema = (i18n: I18n) =>
    yup.object().shape({
        address: getAddressSchema(i18n),
        currentPurpose: stringRequired(i18n),
        afterTransactionPurpose: stringRequired(i18n),
        mortgage: getOtherPropertyMortgageSchema(i18n),
        estimatedPropertyValue: estimatedPropertyValueSchema(i18n),

        // IS SOLD
        currentSaleStatus: currentSaleStatusSchema(i18n),
        purchasePrice: moneyRequiredIfSold(i18n),
        sellingDate: sellingDateSchema(i18n),

        // NOT SOLD CLIENT KEEP this other / owned property
        type: yup.string().emptyAsUndefined().nullable(),
        taxes: getOtherPropertyTaxesSchema(i18n),
        rentalIncome: getOtherPropertyRentalIncomeSchema(i18n),
        rentalIncomeRatio: getOtherPropertyRentalIncomeRatioSchema(i18n),
        condoFees: getOtherPropertyCondoFeesSchema(i18n),
        generalExpenses: generalExpensesSchema(i18n),
        heatingCost: yup.object().when(['type', 'condoFees'], {
            is: (
                type: OtherPropertyType,
                condoFees: Partial<CoOwnershipFees>
            ) => {
                const isHeatingRequired =
                    type === 'CONDO' &&
                    !isEmpty(condoFees?.heatingIncluded) &&
                    booleanNormalizer(condoFees?.heatingIncluded) === false;

                return isHeatingRequired;
            },
            then: getAmountFrequencyRequiredSchema(i18n),
            otherwise: yup.object().optional().nullable(),
        }),

        // BRIDGE LOAN
        bridgeLoanRequired: stringRequiredIfBridgeLoanInfoEnabledAndSold(i18n),
        bridgeLoanAmount: yup.number().when('bridgeLoanRequired', {
            is: (bridgeLoanRequired: 'true' | 'false') =>
                bridgeLoanRequired === 'true',
            then: moneyRequired(i18n).when(
                ['purchasePrice', 'mortgage.balance'],
                // @ts-ignore
                (purchasePrice: number, mortgageBalance: number, schema) =>
                    schema.test({
                        test: (bridgeLoanAmount: number) =>
                            bridgeLoanAmount <=
                            0.95 * (purchasePrice - mortgageBalance),
                        message: i18n._({ id: 'bridgeLoanValidation.error' }),
                    })
            ),
            otherwise: yup.number().emptyAsUndefined().nullable(),
        }),
        bridgeLoanRate: yup.number().emptyAsUndefined().nullable(),
        bridgeLoanType:
            stringRequiredIfBridgeLoanRequiredAndBridgeLoanTypeEnabled(i18n),
    });

export const currentSaleStatusSchema = (i18n: I18n) =>
    yup
        .string()
        .when(['afterTransactionPurpose', '$optionalOwnedPropertyFields'], {
            is: (
                afterTransactionPurpose: PurposeAfterTransaction,
                optionalOwnedPropertyFields: OwnedPropertyValidationField[]
            ) =>
                isSold(afterTransactionPurpose) &&
                !optionalOwnedPropertyFields?.includes('currentSaleStatus'),
            then: stringRequired(i18n),
            otherwise: yup.string().nullable(),
        });

export const stringRequiredIfBridgeLoanInfoEnabledAndSold = (i18n: I18n) =>
    yup
        .string()
        .when(['$enableBridgeLoanInformation', 'afterTransactionPurpose'], {
            is: (
                enableBridgeLoanInformation: boolean,
                afterTransactionPurpose: PurposeAfterTransaction
            ) => enableBridgeLoanInformation && isSold(afterTransactionPurpose),
            then: stringRequired(i18n),
            otherwise: yup.string().nullable(),
        });

export const sellingDateSchema = (i18n: I18n) =>
    yup
        .date()
        .when(['afterTransactionPurpose', '$optionalOwnedPropertyFields'], {
            is: (
                afterTransactionPurpose: PurposeAfterTransaction,
                optionalOwnedPropertyFields: OwnedPropertyValidationField[]
            ) =>
                isSold(afterTransactionPurpose) &&
                !optionalOwnedPropertyFields?.includes('sellingDate'),
            then: yup
                .date()
                .nullable()
                .emptyAsUndefined()
                .fixBackendIssue0001()
                .required(i18n._({ id: 'error.fieldRequired' }))
                .min(new Date(), i18n._({ id: 'error.dateShouldBeFuture' })),
            otherwise: yup
                .date()
                .emptyAsUndefined()
                .fixBackendIssue0001()
                .nullable()
                .optional(),
        });

export const stringRequiredIfNotSold = (i18n: I18n) =>
    yup.string().when('afterTransactionPurpose', {
        is: (afterTransactionPurpose: PurposeAfterTransaction) =>
            afterTransactionPurpose !== 'SOLD',
        then: stringRequired(i18n),
        otherwise: yup.string().nullable(),
    });

export const stringRequiredIfKeep = (i18n: I18n) =>
    yup.string().when('afterTransactionPurpose', {
        is: (afterTransactionPurpose: PurposeAfterTransaction) =>
            afterTransactionPurpose !== 'SOLD',
        then: stringRequired(i18n),
        otherwise: yup.string().nullable(),
    });

export const moneyRequiredIfSold = (i18n: I18n) =>
    yup.number().when('afterTransactionPurpose', {
        is: (afterTransactionPurpose: PurposeAfterTransaction) =>
            isSold(afterTransactionPurpose),
        then: moneyRequired(i18n)
            .min(1, i18n._({ id: 'error.fieldRequired' }))
            .emptyAsUndefined(),
        otherwise: yup.number().isValueNaN().optional().nullable(),
    });

export const moneyRequiredIfKeep = (i18n: I18n) =>
    yup.number().when('afterTransactionPurpose', {
        is: (afterTransactionPurpose: PurposeAfterTransaction) =>
            !isSold(afterTransactionPurpose),
        then: moneyRequired(i18n)
            .min(1, i18n._({ id: 'error.fieldRequired' }))
            .emptyAsUndefined(),
        otherwise: yup.number().isValueNaN().nullable().optional(),
    });

const otherPropertyMortgage = (i18n: I18n, isBridgeLoanRequired?: boolean) =>
    yup.object().shape({
        balance: isBridgeLoanRequired
            ? mortgageBalanceRequired(i18n)
            : yup.number().emptyAsUndefined().nullable(),
        existingLoanNumber: yup.string().emptyAsUndefined().nullable(),
        payment: getOtherPropertyMortgagePaymentSchema(i18n),
        interestRate: yup.number().emptyAsUndefined().nullable(),
        interestRateType: yup.string().emptyAsUndefined().nullable(),
        maturityDate: yup.string().emptyAsUndefined().nullable(),
        mortgageType: yup.string().emptyAsUndefined().nullable(),
        lender: yup.string().emptyAsUndefined().nullable(),
        termType: yup.string().emptyAsUndefined().nullable(),
        portIndicator: getOtherPropertyMortgagePortIndicatorSchema(i18n),
    });

export const stringRequiredIfBridgeLoanRequiredAndBridgeLoanTypeEnabled = (
    i18n: I18n
) =>
    yup.string().when(['$enableBridgeLoanTypes', 'bridgeLoanRequired'], {
        is: (
            enableBridgeLoanTypes: boolean,
            bridgeLoanRequired: 'true' | 'false'
        ) => enableBridgeLoanTypes && bridgeLoanRequired === 'true',
        then: stringRequired(i18n).typeError(i18n._({ id: 'error.typeError' })),
        otherwise: yup.string().nullable(),
    });

export const estimatedPropertyValueSchema = (i18n: I18n) =>
    yup.number().when(['afterTransactionPurpose'], {
        is: (afterTransactionPurpose: PurposeAfterTransaction) =>
            !isSold(afterTransactionPurpose),
        then: moneyRequired(i18n)
            .min(1, i18n._({ id: 'error.fieldRequired' }))
            .emptyAsUndefined(),
        otherwise: yup.number().when(['$mappedSellingPrice'], {
            is: (
                mappedSellingPrice: OwnedProperties['propertyDetails']['mappedSellingPrice']
            ) => mappedSellingPrice === 'estimatedPropertyValue',
            then: moneyRequired(i18n)
                .min(1, i18n._({ id: 'error.fieldRequired' }))
                .emptyAsUndefined(),
            otherwise: yup.number().isValueNaN().nullable(),
        }),
    });
