import subYears from 'date-fns/subYears';

import { MARITAL_STATUS, SALUTATION } from 'constants/appConstants';
import { AssetInstitution } from 'types/asset';

import { getApplicantInfoEmailSchema } from './applicant-info-partial';
import { numberRequired } from './number';
import { stringRequired } from './string';
import yup from './yup-extended';

import type { I18n } from '@lingui/core';
import type { Mutable } from 'types';

export const getApplicantInfoSchema = (i18n: I18n, isNew = false) =>
    yup.object().shape({
        applicantId: isNew
            ? yup.number().isValueNaN().notRequired().nullable().default(0)
            : numberRequired(i18n),
        salutation: yup
            .mixed()
            .oneOf(
                [null, ...(SALUTATION as Mutable<typeof SALUTATION>)],
                i18n._({ id: 'error.typeError' })
            ),
        maritalStatus: yup
            .mixed()
            .oneOf(
                MARITAL_STATUS as Mutable<typeof MARITAL_STATUS>,
                i18n._({ id: 'error.fieldRequired' })
            ),
        dateOfBirth: yup
            .date()
            .emptyAsUndefined()
            .nullable()
            .requiredInArray('dob', i18n)
            .max(
                subYears(Date.now(), 18),
                i18n._({ id: 'error.dateMin18Required' })
            ),
        firstName: yup.string().requiredInArray('firstName', i18n),
        lastName: yup.string().requiredInArray('lastName', i18n),
        phone: yup
            .string()
            .requiredInArray('phone', i18n)
            .phone(i18n._({ id: 'error.invalidPhone' })),
        email: getApplicantInfoEmailSchema(i18n),
        creditScoreQuality: yup
            .string()
            .emptyAsUndefined()
            .nullable()
            .when('$isInternalRenewal', {
                is: (isInternalRenewal: boolean) => !isInternalRenewal,
                then: yup.string().requiredInArray('creditScoreQuality', i18n),
            }),
        primaryBankingInstitution: stringRequired(i18n)
            .emptyAsUndefined()
            .nullable(),
        primaryBankingInstitutionOther: yup
            .string()
            .when('primaryBankingInstitution', {
                is: (primaryBankingInstitution: AssetInstitution) =>
                    primaryBankingInstitution === 'OTHER',
                then: stringRequired(i18n).emptyAsUndefined(),
                otherwise: yup.string().optional().emptyAsUndefined(),
            }),
        covid19Impacted: yup.boolean().emptyAsUndefined().nullable(),
        covid19ImpactDescription: yup.string().when('covid19Impacted', {
            is: (covid19Impacted: boolean) => covid19Impacted,
            then: stringRequired(i18n).emptyAsUndefined().nullable(),
            otherwise: yup.string().optional().emptyAsUndefined(),
        }),
        firstTimeHomeBuyer: yup
            .boolean()
            .required(i18n._({ id: 'error.fieldRequired' }))
            .emptyAsUndefined()
            .nullable(),
        hasConsumerProposalOrBankruptcyLast5yrs: stringRequired(i18n)
            .emptyAsUndefined()
            .nullable(),
        relationToMainApplicant: yup.string().when('$isMainApplicant', {
            is: (isMainApplicant: boolean) => !isMainApplicant,
            then: stringRequired(i18n),
            otherwise: yup.string().optional().emptyAsUndefined(),
        }),
        numberOfDependents: yup
            .number()
            .isValueNaN()
            .optional()
            .nullable()
            .default(0)
            .emptyAsUndefined(),
        newToCanada: yup.boolean().emptyAsUndefined().optional().default(false),
        residencyStatus: yup
            .string()
            .nullAsEmpty()
            .when('$isInternalRenewal', {
                is: (isInternalRenewal: boolean) => !isInternalRenewal,
                then: yup.string().requiredInArray('residencyStatus', i18n),
            }),
        isNestoEmployee: yup
            .boolean()
            .emptyAsUndefined()
            .optional()
            .default(false),
        isDigital: yup.boolean().emptyAsUndefined().optional(),
        divorcedOrSeparated: yup
            .boolean()
            .emptyAsUndefined()
            .optional()
            .default(null)
            .nullable(),
        hasOwnerOccupancy: yup
            .boolean()
            .emptyAsUndefined()
            .default(null)
            .nullable()
            .when('$isInternalRenewal', {
                is: (isInternalRenewal: boolean) => !isInternalRenewal,
                then: yup.boolean().requiredInArray('hasOwnerOccupancy', i18n),
            }),
        guarantor: yup // Only Required for co-applicants
            .boolean()
            .emptyAsUndefined()
            .requiredInArray('guarantor', i18n),
        mpiInsurerResponse: yup.string().nullable().optional(),
        verbalConsent: yup.boolean().optional().emptyAsUndefined(),
        amlScore: yup
            .number()
            .isValueNaN()
            .optional()
            .nullable()
            .min(0, i18n._({ id: 'error.invalidAmlScore' }))
            .max(28.5, i18n._({ id: 'error.invalidAmlScore' })),
    });

export const getApplicantServicingSchema = (i18n: I18n) =>
    yup.object().shape({
        financialInstitutionBankID: yup
            .string()
            .nullable()
            .required(i18n._({ id: 'error.fieldRequired' }))
            .length(3, i18n._({ id: 'error.exactLength', values: { len: 3 } })),
        financialInstitutionAccountNumber: stringRequired(i18n).nullable(),
        financialInstitutionTransitNumber: yup
            .string()
            .nullable()
            .required(i18n._({ id: 'error.fieldRequired' }))
            .length(5, i18n._({ id: 'error.exactLength', values: { len: 5 } })),
    });

export const getApplicantAdditionalInformationSchema = (i18n: I18n) =>
    yup
        .object({
            sin: stringRequired(i18n)
                .trim()
                .canadianSin(i18n._({ id: 'error.isCanadianSIN' })),
        })
        .required();
