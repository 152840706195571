import React, { useMemo } from 'react';

import { Flex, Grid, Typography } from '@nestoca/ui';
import cn from 'classnames';
import { useFormContext } from 'react-hook-form';

import { EditableCell } from 'components/editable';
import { IncludeCheckboxWithTooltip } from 'components/popper/include-checkbox-with-tooltip';
import { IncludeCheckbox } from 'components/scenarios/components/checkbox';
import { useIncomeFrequencyOptions } from 'constants/income-frequency';
import { useI18n } from 'providers/i18n/use-i18n';
import { useEditingRights } from 'store/rights';
import { compareForId } from 'utils/validations/comparators';

import { OnToggleIncluded } from '../employment-type';

import styles from './section.module.scss';

import type { AmountFrequency } from 'types';
import type {
    IncomeEmployment,
    IncomeIncludedFieldName,
} from 'types/applicant';

const showFrequency = (salary: AmountFrequency) => {
    return salary?.amount ? salary.frequency : null;
};

type Props = {
    isCreating: boolean;
    isEditing: boolean;
    employment: IncomeEmployment & { totalIncome?: number };
    onToggleIncluded?: OnToggleIncluded;
};

export const SelfEmployedIncome = ({
    isCreating,
    isEditing,
    employment,
    onToggleIncluded,
}: Props) => {
    const { i18n } = useI18n();

    const { watch } = useFormContext() || {};

    const hasEditingRights = useEditingRights();

    const {
        incomeOverride,
        incomeOverrideIncluded,
        isCurrent,
        salary,
        salaryPreviousYear,
        selfEmployed,
    } = employment;

    let incomeOverrideAmountWatch = incomeOverride?.amount;

    let incomeOverrideFrequencyWatch = incomeOverride?.frequency;

    if (watch) {
        incomeOverrideAmountWatch = watch('incomeOverride.amount');

        incomeOverrideFrequencyWatch = watch('incomeOverride.frequency');
    }

    const handleToggleIncluded = (
        checked: boolean,
        fieldName: IncomeIncludedFieldName
    ) => {
        onToggleIncluded?.(checked, fieldName);
    };

    const showHiddenFields = useMemo(
        () => !isEditing && hasEditingRights,
        [hasEditingRights, isEditing]
    );

    const incomeFrequencyOptions = useIncomeFrequencyOptions();

    return (
        <Flex
            className={cn(styles.section, {
                [styles['section--modal']]: isCreating,
            })}
            direction="column"
            gap={4}
        >
            <Typography weight={7} textColor="var(--color-black)">
                {i18n._('incomeDetails')}
            </Typography>
            <Grid
                className={
                    isEditing ? styles['editing-incomes'] : styles.incomes
                }
                gap={4}
            >
                <EditableCell
                    required={
                        !!incomeOverrideFrequencyWatch &&
                        !incomeOverrideAmountWatch
                    }
                    isEditing={isEditing}
                    name="incomeOverride.amount"
                    label="incomeOverride"
                    fieldType="money"
                    value={incomeOverride?.amount}
                    isFieldInvalidCompareFn={compareForId(employment)}
                />
                <EditableCell
                    required={
                        !!incomeOverrideAmountWatch &&
                        !incomeOverrideFrequencyWatch
                    }
                    isEditing={isEditing}
                    isClearable
                    name="incomeOverride.frequency"
                    label="frequency"
                    fieldType="select"
                    options={incomeFrequencyOptions}
                    value={showFrequency(incomeOverride)}
                    isFieldInvalidCompareFn={compareForId(employment)}
                />
                {!isEditing && hasEditingRights && (
                    <IncludeCheckbox
                        handleToggle={handleToggleIncluded}
                        checked={incomeOverrideIncluded}
                        fieldName="incomeOverrideIncluded"
                        disabled={isNaN(incomeOverride?.amount)}
                        includeTitle="includeInIncome"
                    />
                )}
                <EditableCell
                    required
                    isEditing={isEditing}
                    name="salary.base.amount"
                    label="income"
                    fieldType="money"
                    value={salary?.base?.amount}
                    isFieldInvalidCompareFn={compareForId(employment)}
                />
                <EditableCell
                    required
                    isEditing={isEditing}
                    name="salary.base.frequency"
                    label="frequency"
                    fieldType="select"
                    options={incomeFrequencyOptions}
                    value={salary?.base?.frequency}
                    isFieldInvalidCompareFn={compareForId(employment)}
                />
                {showHiddenFields && (
                    <IncludeCheckboxWithTooltip
                        shouldShowTooltip={!isCurrent && !!salary?.base?.amount}
                        handleToggleIncluded={handleToggleIncluded}
                        checked={salary?.baseRatiosIncluded && isCurrent}
                        disabled={
                            !salary?.base?.amount ||
                            incomeOverrideIncluded ||
                            !isCurrent
                        }
                        fieldName="baseRatiosIncluded"
                    />
                )}
                <EditableCell
                    required
                    isEditing={isEditing}
                    name="selfEmployed.grossRevenue.amount"
                    label="grossRevenueCurrentYear"
                    fieldType="money"
                    value={selfEmployed?.grossRevenue?.amount}
                    isFieldInvalidCompareFn={compareForId(employment)}
                />
                <EditableCell
                    required
                    isEditing={isEditing}
                    name="selfEmployed.grossRevenue.frequency"
                    label="frequency"
                    fieldType="select"
                    options={incomeFrequencyOptions}
                    value={selfEmployed?.grossRevenue?.frequency}
                    isFieldInvalidCompareFn={compareForId(employment)}
                    css={{ gridColumn: !isEditing && 'span 2' }}
                />
                <EditableCell
                    required
                    isEditing={isEditing}
                    name="salaryPreviousYear.base.amount"
                    label="totalTaxableIncomePreviousYear"
                    fieldType="money"
                    value={salaryPreviousYear?.base?.amount}
                    isFieldInvalidCompareFn={compareForId(employment)}
                />
                <EditableCell
                    required
                    isEditing={isEditing}
                    name="salaryPreviousYear.base.frequency"
                    label="frequency"
                    fieldType="select"
                    options={incomeFrequencyOptions}
                    value={salaryPreviousYear?.base?.frequency}
                    isFieldInvalidCompareFn={compareForId(employment)}
                    css={{ gridColumn: !isEditing && 'span 2' }}
                />
                <EditableCell
                    required
                    isEditing={isEditing}
                    name="selfEmployed.grossRevenuePreviousYear.amount"
                    label="grossRevenuePreviousYear"
                    fieldType="money"
                    value={selfEmployed?.grossRevenuePreviousYear?.amount}
                    isFieldInvalidCompareFn={compareForId(employment)}
                />
                <EditableCell
                    required
                    isEditing={isEditing}
                    name="selfEmployed.grossRevenuePreviousYear.frequency"
                    label="frequency"
                    fieldType="select"
                    options={incomeFrequencyOptions}
                    value={selfEmployed?.grossRevenuePreviousYear?.frequency}
                    isFieldInvalidCompareFn={compareForId(employment)}
                />
            </Grid>
        </Flex>
    );
};
