import React, { useCallback, useImperativeHandle, useMemo } from 'react';

import { Trans } from '@lingui/react';
import { Box, Button, Flex } from '@nestoca/ui';
import { Link } from '@tiptap/extension-link';
import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { useFormContext } from 'react-hook-form';

import ApplicantSelector from './applicants-selector/applicant-selector';
import styles from './Tiptap.module.scss';

import type { NodeType } from 'types/notes';
import type { NotesStateTypes } from 'types/notes';

interface TiptapProps {
    applicationId: number;
    noteState: NotesStateTypes;
}

export const Tiptap = React.forwardRef(
    ({ noteState, applicationId }: TiptapProps, ref) => {
        const editor = useEditor({
            /**
             * StartKit contains a lot of the markup tools used in the editor
             * @see https://tiptap.dev/api/extensions/starter-kit
             */
            extensions: [StarterKit, Link],
            editorProps: {
                attributes: {
                    class: styles['tiptap-editor'],
                },
            },
            content: '',
        });

        const {
            formState: { isSubmitting },
            setValue,
        } = useFormContext();

        const handleSubmit = useCallback(() => {
            if (editor) {
                setValue('text', editor.getText());
            }
        }, [editor, setValue]);

        /**
         * This is used to allow parent components to call methods on this component
         * Since the form state is handled by the Tiptap lib, we have to resort to this
         * to perform manual updates.
         *
         * To use this, assign a ref to the Tiptap component inside your parent
         * and call ref.current.yourMethod() as needed.
         */
        useImperativeHandle(ref, () => ({
            clearContent() {
                if (editor) {
                    editor.commands.clearContent();
                }
            },
        }));

        const { section, noteType, nodeType, applicantId } = noteState;
        const displayApplicantSelector = useMemo(() => {
            const acceptedNodeTypes: NodeType[] = ['underwriter', 'advisor'];
            return (
                applicationId &&
                    section !== 'property' &&
                    acceptedNodeTypes.includes(nodeType) &&
                    noteType !== 'submission',
                [applicationId, nodeType, noteType, section]
            );
        }, [applicationId, nodeType, noteType, section]);

        return (
            <Flex
                className={styles['container']}
                direction="column"
                justify="center"
                gap={4}
            >
                {displayApplicantSelector && (
                    <ApplicantSelector
                        applicationId={applicationId}
                        isEditing
                        applicantId={applicantId}
                    />
                )}
                {/* No point in showing markup text options if we don't have the ability to render it
                We need to save as JSON and render React components from the JSON payload */}
                {/* <MarkupButtons editor={editor} /> */}
                <EditorContent editor={editor} />
                <Box>
                    <Button
                        type="submit"
                        variant="primary"
                        disabled={isSubmitting}
                        isLoading={isSubmitting}
                        onClick={handleSubmit}
                    >
                        <Trans id="save" />
                    </Button>
                </Box>
            </Flex>
        );
    }
);

export default Tiptap;
