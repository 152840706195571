import React, { useMemo, useEffect } from 'react';

import { useFlags } from 'launchdarkly-react-client-sdk';
import { useFormContext } from 'react-hook-form';
import { useRecoilValue } from 'recoil';

import { ProblemType } from 'constants/problem';
import { getProblemsBySection } from 'store/applications';
import { SelectOptions } from 'types';
import { useProblems } from 'utils/use-problems';

import { Applicant, Details, Payment, Tds } from '../section';

import type { Liability } from 'types/applicant';

type Props = {
    isCreating?: boolean;
    isEditing: boolean;
    readonly?: boolean;
    liability: Liability;
    applicationId: number;
    applicantsSelectOptions: SelectOptions<number>;
    partialSchema: boolean;
    onToggleIncluded?: (checked: boolean) => void;
};

export const Sections = ({
    isCreating = false,
    isEditing,
    liability,
    applicationId,
    applicantsSelectOptions: applicantOptions,
    partialSchema,
    onToggleIncluded,
}: Props) => {
    const methods = useFormContext();
    const { showValidationProblems, showIncludeTdsInLiabilities } = useFlags();

    // watch liability applicantId
    let applicantId = liability?.applicantId;
    let coOwnerApplicantIds = liability?.coOwnerApplicantIds;
    const sectionProblems = useRecoilValue(
        getProblemsBySection({
            applicationId: applicationId || 0,
            section: ProblemType.liabilities,
        })
    );

    useProblems(
        showValidationProblems ? sectionProblems : [],
        applicantId,
        liability
    );

    if (methods) {
        const { watch } = methods;

        applicantId = watch('applicantId', liability?.applicantId);
        coOwnerApplicantIds = watch(
            'coOwnerApplicantIds',
            liability?.coOwnerApplicantIds || []
        );
    }

    useEffect(() => {
        if (methods && coOwnerApplicantIds?.includes(applicantId)) {
            // remove applicant id from coowners list
            methods.setValue(
                'coOwnerApplicantIds',
                coOwnerApplicantIds.filter((id) => id !== applicantId)
            );
        }
    }, [methods, applicantId, coOwnerApplicantIds]);

    // get the co applicant list excluding current liability applicant
    const coOwnerApplicantOptions = useMemo(
        () =>
            (applicantOptions || []).filter(
                (option) => option.value !== applicantId
            ),
        [applicantId, applicantOptions]
    );

    return (
        <>
            {isCreating && (
                <Applicant
                    isCreating={isCreating}
                    isEditing={isEditing}
                    applicantId={applicantId}
                    applicantOptions={applicantOptions}
                />
            )}
            {showIncludeTdsInLiabilities && (
                <Tds
                    isCreating={isCreating}
                    isEditing={isEditing}
                    liability={liability}
                    onToggleIncluded={onToggleIncluded}
                />
            )}
            <Details
                isCreating={isCreating}
                isEditing={isEditing}
                coOwnerApplicantIds={coOwnerApplicantIds}
                coOwnerApplicantOptions={coOwnerApplicantOptions}
                liability={liability}
                partialSchema={partialSchema}
            />
            <Payment
                isCreating={isCreating}
                isEditing={isEditing}
                liability={liability}
            />
        </>
    );
};
