import { Flex, Grid, Typography } from '@nestoca/ui';

import { EditableCell } from 'components/editable';
import { FREQUENCY } from 'constants/appConstants';
import { useI18n } from 'providers/i18n/use-i18n';
import { GeneralExpenses as GeneralExpensesType } from 'types/general-expenses';
import { maxTwoDecimal } from 'utils';

import styles from './property-detail-section.module.scss';

type GeneralExpensesProps = {
    isEditing: boolean;
    generalExpenses: GeneralExpensesType;
};

export const GeneralExpenses = ({
    isEditing,
    generalExpenses,
}: GeneralExpensesProps) => {
    const { i18n } = useI18n();

    return (
        <Flex
            className={styles['property-detail-section']}
            direction="column"
            gap={4}
        >
            <Typography size={0} weight={6}>
                {i18n._('generalExpenses')}
            </Typography>

            <Grid className={styles.grid} gap={4}>
                <EditableCell
                    name="generalExpenses.amount"
                    label="amount"
                    fieldType="money"
                    isEditing={isEditing}
                    value={generalExpenses?.amount}
                />

                <EditableCell
                    name="generalExpenses.frequency"
                    label="frequency"
                    fieldType="select"
                    isEditing={isEditing}
                    value={generalExpenses?.frequency}
                    options={FREQUENCY(i18n)}
                    isClearable
                />

                <EditableCell
                    name="generalExpenses.ratio"
                    label="Offset"
                    fieldType="percentage"
                    isEditing={isEditing}
                    value={maxTwoDecimal(generalExpenses?.ratio || 0)}
                />
            </Grid>
        </Flex>
    );
};
